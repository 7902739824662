import React, { useEffect } from "react";
import AllUserCard from "./AllUserCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getAllActiveUser } from "../../store/adminSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";

export default function AllUsers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, activeUsers } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(getAllActiveUser());
  }, [dispatch]);

  useEffect(() => {
    if (message === "Status updated successfully") {
      toast.success(message);
      dispatch(getAllActiveUser());
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error, dispatch]);

  return (
    <div className="">
      {loading ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {activeUsers?.length > 0 ? (
            activeUsers.map((item) => (
              <AllUserCard key={item._id} item={item} />
            ))
          ) : (
            <div className="col-span-2 sm:col-span-3 md:col-span-4 text-center">
              <p className="text-lg text-gray-600">No Users Found</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
