import React from "react";
import AllTemplates from "../../components/contract-management-components/AllTemplates";
import AdminDashboardLayout from "../../layout/AdminDashboardLayout";

export default function AllTemplatesPage() {
  return (
    <>
      <AdminDashboardLayout>
        <AllTemplates />
      </AdminDashboardLayout>
    </>
  );
}
