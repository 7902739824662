import React from "react";
import { Link } from "react-router-dom";

// This could be a local file or an online icon for .docx files
const docxPlaceholder = "https://example.com/path-to-docx-icon.png";

export default function TemplateCard({ item }) {
  // Check if the file is a .docx and use a placeholder image instead
  const templateImage = item?.template.endsWith(".docx")
    ? docxPlaceholder
    : item?.template;

  return (
    <div className="w-full">
      <div className="relative bg-white rounded-lg shadow-lg overflow-hidden group h-full flex flex-col">
        {/* Image or Placeholder */}
        {item?.template.endsWith(".docx") ? (
          // Embed the Google Docs Viewer iframe if it's a .docx file
          <iframe
            src={`https://docs.google.com/gview?url=${item.template}&embedded=true`}
            title={item?.name}
            className="w-full h-40 object-cover"
          ></iframe>
        ) : (
          // Otherwise, show an image
          <img
            src={templateImage || "/assets/images/no_image.jpg"}
            alt={item?.name}
            className="w-full h-40 object-cover"
          />
        )}

        {/* Card Content */}
        <div className="p-4 flex-grow">
          <h2 className="text-xl font-semibold text-gray-800">{item?.name}</h2>
          <p className="text-gray-600 mt-2">
            Choose this template for your contract needs.
          </p>
        </div>
        <Link to={`create-contract/${item?._id}`} className="flex justify-center rounded-lg m-2 p-2 bg-usetheme hover:bg-usetheme_hover text-white cursor-pointer">Use</Link>

        {/* Overlay with plus button */}
        {/* <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <button 
            onClick={() => {} }
            className="text-white text-4xl bg-gray-800 p-4 rounded-full hover:bg-gray-700">
            +
          </button>
        </div> */}
      </div>
    </div>
  );
}
