import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/adminSlice";
import ButtonForm from "../buttons/ButtonForm";
import { handleEnterSubmit } from "../../constant";

export default function AdminProfileInfoSettingForm({ data, loading }) {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const inputRef = useRef(null);

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    accountNumber: "",
    password: "",
    address: "",
  });

  useEffect(() => {
    if (data) {
      setFormState({
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        username: data.username || "",
        email: data.email || "",
        account: data.account || "",
        password: data.password || "",
        address: data.address || "",
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSubmit = (e) => {
    // e.preventDefault();

    const updatedData = { ...formState };
    if (image) {
      const formData = new FormData();
      formData.append("picture", image);
      Object.keys(updatedData).forEach((key) =>
        formData.append(key, updatedData[key])
      );

      dispatch(updateUser({ data: formData, id: data?._id }));
    } else {
      dispatch(updateUser({ data: updatedData, id: data?._id }));
    }
  };
  return (
    <>
      {/* forms */}
      <div className="w-full bg-white rounded-xl p-3 mb-4 border-l-4 border-usetheme">
        <h1 className="text-[#18120F] text-lg md:text-lg font-semibold p-1">
          Edit Profile
        </h1>
        <form
          onKeyDown={(e) => handleEnterSubmit(e, handleSubmit)}
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
        >
          {/* Role */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Role
              </label>
              <input
                className="bg-[#f2f2f2] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none cursor-not-allowed"
                type="text"
                value="User"
                disabled
              />
            </div>
          </div>
          {/* Name Inputs */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                First Name
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="firstName"
                value={formState.firstName}
                onChange={handleChange}
              />
            </div>

            {/* Last Name */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Last Name
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="lastName"
                value={formState.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Username and Email */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Username
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="username"
                value={formState.username}
                onChange={handleChange}
              />
            </div>

            {/* Email */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Email
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="email"
                name="email"
                value={formState.email}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Password */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Password
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="password"
                value={formState.password}
                onChange={handleChange}
              />
            </div>

            {/* Account */}
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Account Number
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="account"
                value={formState.account}
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Address */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Address
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="text"
                name="address"
                value={formState.address}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Upload Logo */}
          <div>
            <div className="max-w-36">
              <h2 className="text-[#6B6B6B] text-[12px] ml-1 font-semibold ">
                Upload Logo
              </h2>
              <div
                onClick={handleImageClick}
                className="w-16 h-16 rounded-full  cursor-pointer bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] relative"
              >
                {image ? (
                  <img
                    className="w-16 h-16 rounded-full"
                    src={URL.createObjectURL(image)}
                    alt="Logo"
                  />
                ) : (
                  <img
                    className="w-16 h-16 rounded-full"
                    src={data?.picture || "/assets/icons/img-input-2.svg"}
                    alt="Placeholder"
                  />
                )}
                <input
                  ref={inputRef}
                  onChange={handleImageChange}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          {/* <button
            type="submit"
            className="bg-usetheme text-white rounded-lg h-10 py-2 px-4 mt-4 hover:bg-usetheme_hover"
          >
            {!loading ? (
              "Save Changes"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
            Save Changes
          </button> */}

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Save Changes"
            />
          </div>
        </form>
      </div>
    </>
  );
}
