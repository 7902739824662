import AllTemplatesPage from "../pages/contract-management/AllTemplatesPage";
import CreateContractPage from "../pages/contract-management/CreateContractPage";




const contractManagementRoutes = [
  {
    path: 'all-templates',
    element: <AllTemplatesPage/>,
  },
  {
    path: 'all-templates/create-contract/:templateID',
    element: <CreateContractPage />,
  },

];

export default contractManagementRoutes;
