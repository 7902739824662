import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUserByToken, updatePassword } from "../../store/adminSlice";
import ButtonForm from "../buttons/ButtonForm";

export default function ChangeAdminPassword() {
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);

  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });


  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    hasNumber: false,
    hasUppercase: false,
    hasSpecialChar: false,
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "password") {
      setPasswordCriteria({
        length: value.length >= 8,
        hasNumber: /\d/.test(value),
        hasUppercase: /[A-Z]/.test(value),
        hasSpecialChar: /[@$!%*?&#]/.test(value),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.current_password === "" ||
      formData.password === "" ||
      formData.confirm_password === ""
    ) {
      toast.error("All fields are required");
    } else if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters long");
    } else if (formData.confirm_password !== formData.password) {
      toast.error("Passwords do not match");
    } else if (!Object.values(passwordCriteria).every(Boolean)) {
      toast.error("Password does not meet the required criteria");
    } else {
      dispatch(updatePassword({ token: data?._id, data: formData }));
    }
  };

  return (
    <>
      {/* Forms */}
      <div className="w-full bg-white rounded-xl p-3 mb-4 border-l-4 border-usetheme">
        <h1 className="text-[#18120F] text-lg md:text-lg font-semibold p-1">
          Update Password
        </h1>
        <form
          onSubmit={handleSubmit}
          className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"
        >
          {/* Current Password */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Current Password
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="password"
                name="current_password"
                value={formData.current_password}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* New Password */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                New Password
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Password Strength Feedback */}
          <div className="text-xs text-[#6B6B6B] p-2">
            <p className={`mt-1 ${passwordCriteria.length ? 'text-green-500' : 'text-red-500'}`}>
              {passwordCriteria.length ? '✔' : '✖'} Minimum 8 characters
            </p>
            <p className={`mt-1 ${passwordCriteria.hasNumber ? 'text-green-500' : 'text-red-500'}`}>
              {passwordCriteria.hasNumber ? '✔' : '✖'} Contains a number
            </p>
            <p className={`mt-1 ${passwordCriteria.hasUppercase ? 'text-green-500' : 'text-red-500'}`}>
              {passwordCriteria.hasUppercase ? '✔' : '✖'} Contains an uppercase letter
            </p>
            <p className={`mt-1 ${passwordCriteria.hasSpecialChar ? 'text-green-500' : 'text-red-500'}`}>
              {passwordCriteria.hasSpecialChar ? '✔' : '✖'} Contains a special character (@, $, !, %, *, ?, &)
            </p>
          </div>

          {/* Confirm New Password */}
          <div className="flex flex-col md:flex-row items-center gap-2">
            <div className="flex w-full flex-col md:gap-1">
              <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
                Confirm New Password
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                type="password"
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <ButtonForm
              onClick={handleSubmit}
              loading={loading}
              text="Save Changes"
            />
          </div>
        </form>
      </div>
    </>
  );
}
