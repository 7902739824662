import React from "react";

export default function ButtonForm({
    onClick,
    text = "Click me",
    className = "text-white bg-usetheme hover:bg-usetheme_hover text-xs md:text-sm h-10 font-semibold py-2 px-3 md:px-3 md:py-2 rounded-lg",
    loading
}) {
  return (
    <>
      <button
        onClick={onClick}
        disabled={loading}
        className={className}
      >
        {!loading ? (
          text
        ) : (
          <span className="loading loading-spinner loading-md"></span>
        )}
      </button>
    </>
  );
}
