import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTemplateByID } from "../../store/adminSlice";
import { useParams } from "react-router-dom";
import mammoth from "mammoth";
import axios from "axios";

export default function CreateContract() {
  const dispatch = useDispatch();
  const { templateID } = useParams();
  const { loading, data } = useSelector((state) => state.admin);
  const [templateHtml, setTemplateHtml] = useState(""); 

  // Fetch the template when component loads
  useEffect(() => {
    dispatch(getTemplateByID(templateID));
  }, [dispatch, templateID]);

  // Convert .docx to HTML using mammoth
  useEffect(() => {
    const convertDocxToHtml = async () => {
      if (data?.template) {
        try {
          const response = await axios.get(data.template, {
            responseType: "arraybuffer",
          });

          // Convert the .docx file to HTML using mammoth
          const result = await mammoth.convertToHtml({ arrayBuffer: response.data });
          setTemplateHtml(result.value); 
        } catch (error) {
          console.error("Error converting .docx to HTML:", error);
        }
      }
    };

    convertDocxToHtml();
  }, [data?.template]);

  return (
    <>
      <div className="editor-container">
        <h1>Edit Template</h1>


        {loading ? (
          <p>Loading template...</p>
        ) : (

          <div dangerouslySetInnerHTML={{ __html: templateHtml }} />
        )}
      </div>
    </>
  );
}
