import React from "react";
import { useDispatch } from "react-redux";
import { updateUserStatus } from "../../store/adminSlice";
import ButtonLink from "../buttons/ButtonLink";

export default function AllUserCard({ item }) {
  const dispatch = useDispatch();
  return (
    <>
      <div className="flex gap-2 ">
        <div className="flex flex-col gap-3 w-full  bg-white rounded-xl p-3">
          <div className="border border-[#EBF0ED] rounded-lg px-3 py-2">
            <div className="flex items-center gap-2 "></div>
            <div>
              <div className="flex flex-col sm:flex-row justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  Username :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  @{item?.username}
                </p>
              </div>
              <hr />
              <div className="flex flex-col sm:flex-row justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">Email :</p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {item?.email}
                </p>
              </div>
              <hr />
              <div className="flex items-center gap-1 my-1 mt-2">
                {/* <div
                  onClick={() =>
                    dispatch(updateUserStatus({ id: item._id, isActive: false }))
                  }
                  className="px-3 py-1 bg-transparent border border-usetheme text-usetheme hover:bg-usetheme hover:text-white rounded-lg cursor-pointer"
                >
                  Disable
                </div> */}
                <ButtonLink
                  onClick={() =>
                    dispatch(
                      updateUserStatus({ id: item._id, isActive: false })
                    )
                  }
                  text="Disable"
                  padding="px-3 py-2"
                  activeState="bg-white text-black border border-usetheme"
                  hoverState="hover:bg-usetheme hover:text-white transition duration-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
