import React, { useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getAllServices } from "../../store/adminSlice";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

export default function ServicesManagement() {
  const dispatch = useDispatch();
  const { loading, message, error, data, statusCode } = useSelector(
    (state) => state.admin
  );

  const [services, setServices] = useState([]);

  
  useEffect(() => {
    if (data?.services) {
      setServices(data.services); 
    }
  }, [data?.services]);

  
  useEffect(() => {
    dispatch(getAllServices());
  }, [dispatch]);

  
  const handleServiceDeleted = (deletedServiceId) => {
    setServices((prevServices) =>
      prevServices.filter((service) => service._id !== deletedServiceId)
    );
  };

  
  useEffect(() => {
    if (statusCode === 200 && message) {
      toast.success(message);
    } else if (error) {
      toast.error(error);
    }

   
    dispatch(clearMessage());
  }, [statusCode, message, error, dispatch]);

  return (
    <div className="py-4">
      {loading ? (
        <div className="flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {services.length > 0 ? (
            services.map((item) => (
              <ServiceCard
                key={item._id}
                item={item}
                onServiceDeleted={handleServiceDeleted}  
              />
            ))
          ) : (
            <p className="col-span-full text-center text-gray-600">
              No Services Found
            </p>
          )}
        </div>
      )}
    </div>
  );
}
