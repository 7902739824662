import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearMessage,
  getCoreCompanySetting,
  updateCoreCompanySetting,
} from "../../store/adminSlice"; // Assuming the update action exists
import { toast } from "react-toastify";
import ButtonForm from "../buttons/ButtonForm";

export default function CompanySettingsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, message, error, data, statusCode } = useSelector(
    (state) => state.admin
  );

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    domain: "",
    address: "",
    adminApproval: false,
    sendApprovalNotification: false,
  });

  useEffect(() => {
    dispatch(getCoreCompanySetting());
  }, [dispatch]);

  useEffect(() => {
    if (data && data.coreSettings) {
      setFormData({
        name: data.coreSettings.name || "",
        email: data.coreSettings.email || "",
        domain: data.coreSettings.domain || "",
        address: data.coreSettings.address || "",
        adminApproval: data.coreSettings.adminApproval || false,
        sendApprovalNotification:
          data.coreSettings.sendApprovalNotification || false,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = () => {
    dispatch(updateCoreCompanySetting(formData));
  };

  return (
    <>
    <div className="w-full bg-white rounded-xl">
      <div className="flex justify-between items-center py-2">
        <h1 className="text-[#18120F] text-base md:text-lg font-semibold p-1">
          Company Settings
        </h1>
        {/* <button
          onClick={handleSubmit}
          className="bg-white text-usetheme hover:text-white border rounded-lg h-10 py-2 px-4 mt-4 hover:bg-usetheme"
        >
          {!loading ? (
            "Save Changes"
          ) : (
            <span className="loading loading-spinner loading-md"></span>
          )}
        </button> */}
      </div>
      {/* <div className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase space-y-2"> */}
      {/* Name and Email */}
      {/* <div className="flex flex-col md:flex-row items-center gap-2">
          <div className="flex w-full flex-col md:gap-1">
            <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
              Name
            </label>
            <input
              className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex w-full flex-col md:gap-1">
            <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
              Email
            </label>
            <input
              className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
        </div> */}

      {/* Domain and Address */}
      {/* <div className="flex flex-col md:flex-row items-center gap-2">
          <div className="flex w-full flex-col md:gap-1">
            <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
              Domain
            </label>
            <input
              className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
              type="text"
              name="domain"
              value={formData.domain}
              onChange={handleInputChange}
            />
          </div>

          <div className="flex w-full flex-col md:gap-1">
            <label className="text-[#6B6B6B] text-[12px] ml-1 font-semibold">
              Company Address
            </label>
            <input
              className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
        </div> */}
      {/* </div> */}

      {/* Toggle Switches */}
      <div className="mt-4 space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-[#6B6B6B] text-xxs md:text-xs font-semibold">
            Admin approval for new accounts?
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              name="adminApproval"
              checked={formData.adminApproval}
              onChange={handleInputChange}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-usetheme  peer-focus:ring-blue-300"></div>
            <div className="absolute left-[2px] top-[2px] w-5 h-5 bg-white rounded-full transition-transform peer-checked:translate-x-full"></div>
          </label>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-[#6B6B6B] text-xxs md:text-xs font-semibold">
            Send approval notification?
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              name="sendApprovalNotification"
              checked={formData.sendApprovalNotification}
              onChange={handleInputChange}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-usetheme  peer-focus:ring-blue-300"></div>
            <div className="absolute left-[2px] top-[2px] w-5 h-5 bg-white rounded-full transition-transform peer-checked:translate-x-full"></div>
          </label>
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <ButtonForm
          onClick={handleSubmit}
          loading={loading}
          text="Save Changes"
        />
      </div>
    </div>
    </>
  );
}
