import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import ButtonLink from "../buttons/ButtonLink";

export default function AdmindashboardModulesCard({ item }) {
  return (
    <>
      <div className="w-full sm:w-52 md:w-60 bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.09)] p-5 md:p-8 space-y-3 relative overflow-hidden">
        <div className="w-20 h-20 md:w-24 md:h-24 bg-usetheme rounded-full absolute -right-4 -top-6 md:-right-5 md:-top-7">
          <p className="absolute bottom-5 left-6 text-white text-xl md:text-2xl cursor-pointer">
            <Link to={item.addButton}>
              <FaPlus />
            </Link>
          </p>
        </div>
        <div className="fill-violet-500 w-10 md:w-12">
          <img src={item.icon} alt="" />
        </div>
        <h1 className="font-bold text-lg md:text-xl">{item.title}</h1>
        <p className="text-xs md:text-sm text-zinc-500 leading-5 md:leading-6 line-clamp-2">
          {item.description}
        </p>
        <div className="flex justify-start pb-2 pt-4">
          <ButtonLink
            to={item.buttonLink}
            text={item.buttonText}
            padding="py-2 px-3 md:px-6 md:py-2"
          />
        </div>
      </div>
    </>
  );
}
