import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  deleteCompany,
  getCompanies,
  searchCompany,
} from "../../store/adminSlice";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import DeletePopup from "../../common/DeletePopup";
import { toast } from "react-toastify";
import BreadCrums from "../../common/BreadCrums";
import ButtonLink from "../buttons/ButtonLink";
import { MdExpandLess } from "react-icons/md";

export default function AlllCompaniesTable() {
  const dispatch = useDispatch();
  const { loading, message, error, data, statusCode } = useSelector(
    (state) => state.admin
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [expandedRow, setExpandedRow] = useState(null);
  const detailsRef = useRef([]);

  useEffect(() => {
    if (data?.companies?.length >= 0) {
      setExpandedRow(data?.companies[0]?._id); 
    }
  }, [data]); 
  const toggleRow = (id) => {
    setExpandedRow((prev) => (prev === id ? null : id));
  };

  const getRowHeight = (index) => {
    if (
      detailsRef?.current[index] &&
      expandedRow === data?.companies[index]._id
    ) {
      return `${detailsRef?.current[index].scrollHeight}px`; // Dynamic height based on content
    }
    return "0px";
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedCompanyId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteCompany(selectedCompanyId)).then(() => {
      dispatch(getCompanies());
    });
    setShowDeletePopup(false); 
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false); 
  };

  const handleSearch = (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);


    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (e.key === "Enter") {
      if (query === "") {
        dispatch(getCompanies());
      } else {
        dispatch(searchCompany(query));
      }
    } else {
      const timeout = setTimeout(() => {
        if (query === "") {
          dispatch(getCompanies());
        } else {
          dispatch(searchCompany(query));
        }
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    if (statusCode === 200) {
      toast.success(message);
    }
    if (error) {
      toast.error(error);
    }
    dispatch(clearMessage());
  }, [loading, message, error]);

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center py-5 space-y-4 sm:space-y-0">
          <BreadCrums
            breadCrum={[
              {
                name: "Admin Dashboard",
                path: "/admin-dashboard/",
              },
              {
                name: "Companies",
                path: "",
              },
            ]}
          />
          <ButtonLink to="add-company" text="Add New Company" />
        </div>
        <div className="relative flex items-center w-full">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
            type="search"
            placeholder="Search Companies"
            onChange={handleSearch}
            onKeyDown={handleSearch}
          />
        </div>
      </div>
      <div className="my-5">
        <div className="hidden lg:block overflow-x-auto lg:max-w-[56rem] xl:min-w-full">
          <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            {/* head */}
            <thead>
              <tr className="bg-usetheme text-white">
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Logo
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Name
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Email
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Domain
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  INC
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  VAT
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Address
                </th>
                <th className="py-3 px-2 md:px-6 text-left text-xs md:text-sm font-medium tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan="10">
                    <div className="flex justify-center py-2">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.companies?.length > 0 ? (
                data?.companies?.map((com) => (
                  <tr className="hover:bg-gray-100">
                    <td className="py-4 px-2 md:px-6 text-sm text-gray-700">
                      <img
                        className="h-8 w-8 md:h-10 md:w-10 rounded-full"
                        src={com.logo || "/assets/images/alt-logo.png"}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null; // Prevents looping in case the fallback image also fails to load
                          e.target.src = "/assets/images/alt-logo.png"; // Path to your alternative image
                        }}
                      />
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700">
                      {com.name}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700">
                      {com.email}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm">
                      <a
                        href={com.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {com.domain}
                      </a>
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700">
                      {com.inc}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700">
                      {com.vat}
                    </td>
                    <td className="py-4 px-2 md:px-6 text-xs md:text-sm text-gray-700">
                      {com.address}
                    </td>
                    <td className="mt-4 px-2 md:px-3 flex gap-2 items-center">
                      <Link to={`update-company/${com._id}`}>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/edit.svg"
                          alt="Edit"
                        />
                      </Link>
                      <Link to={`view-company/${com._id}`}>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/view.svg"
                          alt="View"
                        />
                      </Link>
                      <Link onClick={() => handleDeleteClick(com._id)}>
                        <img
                          className="max-w-8 max-h-8 md:max-w-16 md:max-h-16"
                          src="/assets/icons/delete.svg"
                          alt="Delete"
                        />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">
                    <div className="text-center font-bold tracking-wide py-2">
                      No Companies Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="lg:hidden">
          {loading ? (
            <div className="flex justify-center items-center py-2">
              <Loader />
            </div>
          ) : data?.companies?.length > 0 ? (
            data.companies.map((com, index) => (
              <div key={com._id} className="mt-4">
                {/* First Row - Name */}
                <div
                  onClick={() => toggleRow(com._id)}
                  className={`${
                    expandedRow === com._id
                      ? "rounded-tl-xl rounded-tr-xl"
                      : "rounded-xl"
                  } cursor-pointer flex justify-between items-center px-4 py-2 bg-usetheme text-white text-sm `}
                >
                  <div className="font-semibold">Name</div>
                  <div>{com.name}</div>
                  <div>{expandedRow === com._id ? (<MdExpandLess size={24} />) : (<MdExpandLess className="rotate-180" size={24} />)}</div>
                </div>

                {/* Transition Wrapper */}
                <div
                  ref={(el) => (detailsRef.current[index] = el)}
                  style={{
                    maxHeight: getRowHeight(index),
                    transition:
                      "max-height 0.3s ease-out, opacity 0.3s ease-out",
                    overflow: "hidden",
                    opacity: expandedRow === com._id ? 1 : 0,
                  }}
                >
                  {/* Details Rows - Only visible when expandedRow is active */}
                  <div className="flex justify-between items-center bg-white px-4 py-2 text-xxs border-b">
                    <div className="font-semibold">Logo</div>
                    <img
                      className="h-8 w-8 md:h-10 md:w-10 rounded-full"
                      src={com.logo || "/assets/images/alt-logo.png"}
                      alt=""
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/assets/images/alt-logo.png";
                      }}
                    />
                  </div>
                  <div className="flex justify-between bg-white px-4 py-2 text-xxs border-b">
                    <div className="font-semibold">Email</div>
                    <div>{com.email}</div>
                  </div>
                  <div className="flex justify-between bg-white px-4 py-2 text-xxs border-b">
                    <div className="font-semibold">Domain</div>
                    <div>
                      <a
                        href={com.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {com.domain}
                      </a>
                    </div>
                  </div>
                  <div className="flex justify-between bg-white px-4 py-2 text-xxs border-b">
                    <div className="font-semibold">INC</div>
                    <div>{com.inc}</div>
                  </div>
                  <div className="flex justify-between bg-white px-4 py-2 text-xxs border-b">
                    <div className="font-semibold">VAT</div>
                    <div>{com.vat}</div>
                  </div>
                  <div className="flex justify-between bg-white px-4 py-2 text-xxs border-b">
                    <div className="font-semibold">Address</div>
                    <div>{com.address}</div>
                  </div>
                  <div className="rounded-bl-xl rounded-br-xl flex justify-between items-center bg-white px-4 py-2 text-xxs">
                    <div className="font-semibold">Actions</div>
                    <div className="flex gap-2">
                      <Link to={`update-company/${com._id}`}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/edit.svg"
                          alt="Edit"
                        />
                      </Link>
                      <Link to={`view-company/${com._id}`}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/view.svg"
                          alt="View"
                        />
                      </Link>
                      <Link onClick={() => handleDeleteClick(com._id)}>
                        <img
                          className="h-8 w-8"
                          src="/assets/icons/delete.svg"
                          alt="Delete"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center font-bold tracking-wide py-2">
              No Companies Found
            </div>
          )}
        </div>
      </div>

      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation} 
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
