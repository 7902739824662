import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteService } from "../../store/adminSlice";
import DeletePopup from "../../common/DeletePopup";
import ButtonLink from "../buttons/ButtonLink";

export default function ServiceCard({ item, onServiceDeleted }) {
  const dispatch = useDispatch();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedServiceId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteService(selectedServiceId)).then(() => {
      onServiceDeleted(selectedServiceId);
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  return (
    <>
      <div
        role="alert"
        className="mx-auto max-w-lg h-60 rounded-lg border border-stone bg-white p-6 shadow-lg sm:p-6 lg:p-8 flex flex-col justify-between"
      >
        <div className="flex items-center gap-4">
          <span className="shrink-0 rounded-full bg-white p-2">
            <img className="h-6 w-6" src={item.icon} alt={item.title} />
          </span>
          <p className="font-medium sm:text-lg text-black">{item.title}</p>
        </div>

        <p className="mt-4 text-gray-600 line-clamp-2">{item.description}</p>

        <div className="mt-6 flex gap-4">
          {/* Update Button */}
          <ButtonLink
            to={`update-service/${item._id}`}
            text="Update"
            padding="px-3 py-2"
            activeState="bg-usetheme text-white"
            hoverState="hover:bg-usetheme_hover"
          />

          {/* Delete Button */}
          <ButtonLink
            onClick={() => handleDeleteClick(item._id)}
            text="Delete"
            padding="px-3 py-2"
            activeState="bg-white text-black"
            hoverState="hover:bg-usetheme hover:text-white transition duration-300"
          />
        </div>
      </div>

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
