import moment from "moment";
import React, { useState } from "react";
import { FaEnvelope, FaPhoneAlt, FaEdit, FaTrash } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteVisitor } from "../../store/adminSlice";
import DeletePopup from "../../common/DeletePopup";
import { Link } from "react-router-dom";

export default function VisitorCard({ item, onVisitorDeleted }) {
  const dispatch = useDispatch();

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedVisitorId, setSelectedVisitorId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedVisitorId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteVisitor(selectedVisitorId)).then(() => {
      onVisitorDeleted(selectedVisitorId);
    });
    setShowDeletePopup(false);
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };
  return (
    <>
      <div className="w-72 h-64 mx-auto bg-white rounded-lg shadow-sm overflow-hidden p-4 flex flex-col justify-between">
        {/* Visitor Info Section */}
        <div className="flex items-start">
          {/* Profile Image Placeholder */}
          <div className="h-10 w-10 rounded-full bg-usetheme flex items-center justify-center text-white font-bold text-lg mr-4 uppercase">
            {item.name[0]}
          </div>
          <div>
            <h2 className="text-lg font-medium text-gray-800 capitalize">
              {item.name}
            </h2>
            <p className="text-gray-500 text-sm">{item.speciality}</p>
          </div>
        </div>

        {/* Contact Info Section */}
        <div>
          <div className="flex items-center text-gray-600 text-sm mb-2">
            <FaEnvelope className="mr-2" />
            <span>{item.email}</span>
          </div>
          <div className="flex items-center text-gray-600 text-sm mb-2">
            <FaPhoneAlt className="mr-2" />
            <span>
              {item.phone.startsWith("+92")
                ? item.phone
                : item.phone.startsWith("92")
                ? `+${item.phone}`
                : `+92${item.phone}`}
            </span>
          </div>
          <div className="flex items-center text-gray-600 text-sm">
            <MdOutlineDateRange className="mr-2" />
            <span>{moment(item.createdAt).format("DD MMMM YY")}</span>
          </div>
        </div>

        {/* Action Button and Edit/Delete Section */}
        <div className="flex justify-between items-center pt-4 border-t border-gray-200 mt-2">
          {/* <a target="_blank" href={`https://wa.me/${item.phone}`}> */}
            <a target="_blank" rel="noreferrer" href={`https://wa.me/${item.phone}`} className="px-4 py-2 rounded bg-usetheme text-white text-center text-sm font-medium w-full hover:bg-usetheme_hover transition">
              Contact
            </a>
          {/* </a> */}
          <div className="flex space-x-2 ml-4">
            <Link
            to={`update-visitor/${item._id}`}
              className="text-gray-500 hover:text-gray-800 transition"
              aria-label="Edit Visitor"
            >
              <FaEdit />
            </Link>
            <button
              onClick={() => handleDeleteClick(item._id)}
              className="text-gray-500 hover:text-gray-800 transition"
              aria-label="Delete Visitor"
            >
              <FaTrash />
            </button>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation}
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
}
