import AllVisitorsPage from "../pages/visitors-management/AllVisitorsPage";
import VisitorCreationPage from "../pages/visitors-management/VisitorCreationPage";
import VisitorUpdationPage from "../pages/visitors-management/VisitorUpdationPage";




const visitorsManagementRoutes = [
  {
    path: 'manage-visitors',
    element: <AllVisitorsPage />,
  },
  {
    path: 'manage-visitors/add-visitor',
    element: <VisitorCreationPage />,
  },
  {
    path: 'manage-visitors/update-visitor/:id',
    element: <VisitorUpdationPage />,
  },

];

export default visitorsManagementRoutes;
