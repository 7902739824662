import React, { useState } from "react";
import { HiInformationCircle } from "react-icons/hi";

const TailwindTooltip = ({ message, position = "top", children }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle mouse hover to show/hide the tooltip
  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => setIsVisible(false);

  // Tooltip position classes
  const positionClasses = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
    bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-2",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-2",
  };

  return (
    <div
      className="relative flex items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Child element that triggers the tooltip */}
      <HiInformationCircle className="bg-white text-black" size={18} />

      {/* Tooltip box */}
      {isVisible && (
        <div
          className={`absolute whitespace-nowrap bg-black font-normal text-white text-xs px-4 py-2 rounded-lg shadow-lg z-50 ${positionClasses[position]} lowercase tracking-wide`}
        >
          {message}
          <div
            className={`absolute ${
              position === "top" ? "bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full" :
              position === "bottom" ? "top-0 left-1/2 transform -translate-x-1/2 -translate-y-full" :
              position === "left" ? "right-0 top-1/2 transform -translate-y-1/2 translate-x-full" :
              "left-0 top-1/2 transform -translate-y-1/2 -translate-x-full"
            }`}
          >
            {/* <svg
              className="w-3 h-3 fill-current text-gray-800"
              viewBox="0 0 12 6"
            >
              <path d="M0 0h12L6 6z" />
            </svg> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default TailwindTooltip;
